
$white: #ffffff;
$black: #333333;

$kubra-blue-lighter: #19c1ff;
$kubra-blue: #0093c9;
$kubra-blue-darker: #005b7c;

$kubra-green: #82bc00;

$kubra-gray-lighter: #A3B1BD;
$kubra-gray: #566471;
$kubra-gray-darker: #434D57;

$kubra-light-blue: #D5F4FF;
$kubra-light-green: #DDFAF3;

$code-background: #C1E0FD;
$code-alt-background: #dfe2e4;

$color-brandprimary: #007FAD;
$color-brandsecondary: #36434E;
$color-black: #242424;
$color-white: #fff;
$color-gray1: #F3F3F3;
$color-gray2: #E0E0E0;
$color-gray3: #DADADA;
$color-gray4: #EAECEE;
$color-gray5: #F0F0F0;
$color-gray6: #BFBFBF;
$color-gray7: #9E9E9E;
$color-gray8: #7C7C7C;

$color-positive: #018748;
$color-success: #01A457;
$color-error: #DD3227;
$color-disabled: #CACACA;
