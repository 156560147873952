.iconbanner-container {
  position: fixed;
  right: 19vh;
  bottom: 8vh;
  width: 192px;
  height: 41px;
  border: .1rem solid #F3F3F3;
  border-right: none;
  border-radius: 10rem;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  .iconbanner-text {
    display: flex;
    align-items: center;
    color: #007FAD;
    font-weight: 700;
    font-size: 18px;
  }
}

.iconbanner-container--secondary {
  .iconbanner-container {
    position: fixed;
    right: 19vh;
    bottom: 8vh;
    width: 192px;
    height: 41px;
    border: .1rem solid #F3F3F3;
    border-right: none;
    border-radius: 10rem;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    .iconbanner-text {
      display: flex;
      align-items: center;
      color: #007FAD;
      font-weight: 700;
      font-size: 18px;
    }
  }
}
.iconbanner-caret {
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  transform: rotate(45deg);
  background-color: #FFFFFF;
  text-shadow: 0px 0px 0px rgb(255 255 255 / 70%), 0px 4px 4px rgb(0 0 0 / 50%);
  font-size: 32px;
  right: 18.5vh;
  bottom: 9.3vh;
  position: fixed;
  border: 1px solid #F3F3F3;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
  z-index: -1;
}