.cta-container {
  // cursor: pointer;
  // position: fixed;
  // right: 5vh;
  // bottom: 5vh;
  // width: 5.8rem;
  // height: 5.8rem;
  // border: 1px solid $color-gray1;
  // border-radius: 10rem;
  // box-sizing: border-box;
  // box-shadow: 2px 4px 10px rgba(64, 144, 232, 0.5);
  // background: $color-brandprimary;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  // &:hover {
  //   display: flex;
  //   color: $color-brandprimary;
  //   background-color: $color-white;
  // }
  .cta-icon {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 46.37px;
    // height: 50.04px;
    // &:hover {
    //   display: none;
    // }
    // &:hover + .hover-text {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   text-transform: uppercase;
    //   width: 44px;
    //   height: 32px;
    //   color: $color-brandprimary;
    //   background-color: $color-white;
    //   font-size: 16px;
    //   font-weight: 700;
    // }
    // svg {
    //   display: flex;
    //   color: $color-white;
    //   width: 46.37px;
    //   height: 50.04px;
    //   &:hover {
    //     display: none;
    //   }
    // }
  }
  .hover-text {
    // display: none;
  }
}

.cta-container--secondary {
  cursor: pointer;
  position: fixed;
  right: 5vh;
  bottom: 5vh;
  width: 5.8rem;
  height: 5.8rem;
  border: 1px solid $color-gray1;
  border-radius: 10rem;
  box-sizing: border-box;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  .cta-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46.37px;
    height: 50.04px;
    svg {
      display: flex;
      color: $color-brandsecondary;
      width: 46.37px;
      height: 50.04px;
    }
  }
}
